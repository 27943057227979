<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Feriado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <FeriadoForm></FeriadoForm>
    </div>
  </v-container>
</template>
<script>
import FeriadoForm from "../components/FeriadoForm.vue";

export default {
  name: "FeriadoAlta",
  components: {
    FeriadoForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
  created() {},
};
</script>
